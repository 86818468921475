import React from 'react';
import jigaro from './App.module.css';
import Cursor from "./cursor/cursor";
import 'animate.css';
import { useInView } from 'react-intersection-observer';

function App() {

  const [language, setlanguage] = React.useState("GE");
  const [order, setorder] = React.useState(false);

  const { ref: firstpageanimation, inView: firstpageanim } = useInView({triggerOnce: true,});
  const { ref: secondpageanimation, inView: secondpageanim } = useInView({triggerOnce: true,});
  const { ref: thirdpageanimation, inView: thirdpageanim } = useInView({triggerOnce: true,});

  React.useEffect(()=> {
    if (window.localStorage.getItem("USER_LANGUAGE") !== null) {
       setlanguage(window.localStorage.getItem("USER_LANGUAGE"))   
    } 
  }, [])

  function changelanguage(lang) {
    setlanguage(lang)
    window.localStorage.setItem("USER_LANGUAGE",lang)
 }

  const main = React.useRef(null);
  const boxes = React.useRef(null);
  const whyus = React.useRef(null);
  const contact = React.useRef(null);

  return (
    <div>
    <Cursor/>
    <main className={jigaro.app}>
    <div className={jigaro.container}>

    <div className={`${jigaro.header} ${firstpageanim === true ? "animate__animated" : null} ${firstpageanim === true ? "animate__fadeInDown" : null}`} ref={main}>
    <div className={jigaro.header_cont}>
    <div className={jigaro.header_nav}>
    <div className={jigaro.header_logo}></div>
    <a className={jigaro.header_nav_item_container} onClick={() => main.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>
    <h1 className={jigaro.header_nav_item} style={language === "EN" ? {fontFamily: "Nunito " , fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null} >{language === "GE" ? "მთავარი" : language === "EN" ? "MAIN PAGE" : language ==="RU" ? "Главная Страница" : null}</h1 >
    <div className={jigaro.header_nav_item_decline}></div>
    </a>
    <a className={jigaro.header_nav_item_container} onClick={() => boxes.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>
    <h1 className={jigaro.header_nav_item} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null} >{language === "GE" ? "ბოქსები" : language === "EN" ? "OUR BOXES" : language ==="RU" ? "Наши Наборы" : null}</h1>
    <div className={jigaro.header_nav_item_decline}></div>
    </a>
    <a className={jigaro.header_nav_item_container} onClick={() => whyus.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>
    <h1 className={jigaro.header_nav_item} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "რატომ ჩვენ" : language === "EN" ? "WHY US?" : language ==="RU" ? "Почему Мы" : null}</h1>
    <div className={jigaro.header_nav_item_decline}></div>
    </a>
    <a className={jigaro.header_nav_item_container} onClick={() => contact.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>
    <h1 className={jigaro.header_nav_item} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "კონტაქტი" : language === "EN" ? "CONTACT" : language ==="RU" ? "Контакт" : null}</h1>
    <div className={jigaro.header_nav_item_decline}></div>
    </a>
    </div>
    <div className={jigaro.header_langs}>
    <a className={jigaro.header_language} onClick={() => changelanguage("GE")}>GE</a>
    <a className={jigaro.header_language} onClick={() => changelanguage("EN")}>EN</a>
    <a className={jigaro.header_language} onClick={() => changelanguage("RU")}>RU</a>
    </div>
    </div>
    </div>

    <div className={jigaro.firstpage} ref={firstpageanimation} >
    <div className={`${jigaro.firstpage_socials} ${firstpageanim === true ? "animate__animated" : null} ${firstpageanim === true ? "animate__backInLeft" : null}`}>
    <a className={jigaro.firstpage_socials_insta}></a>
    <a className={jigaro.firstpage_socials_fb}></a>
    <a className={jigaro.firstpage_socials_phone}></a>
    </div>
    <div className={`${jigaro.firstpage_left} ${firstpageanim === true ? "animate__animated" : null} ${firstpageanim === true ? "animate__fadeInLeft" : null}`}>
    <h1 className={jigaro.firstpage_left_title} style={language === "EN" ? { fontFamily: "Nunito", fontWeight: "800", lineHeight: "80px"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200" , lineHeight: "80px"} :  null}>{language === "GE" ? "ჯიგაროს ბოქსი" : language === "EN" ? "JIGARO`S BOX" : language ==="RU" ? "JIGARO`S BOX" : null}</h1>
    <h2 className={jigaro.firstpage_left_desc} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "სპეც - რეცეპტით, გრილზე შებოლილი ქათმის ფრთები" : language === "EN" ? "Grilled smoked chicken wings with a special recipe" : language ==="RU" ? "крылышки копченые на гриле по особому рецепту." : null}</h2>
    <h3 className={jigaro.firstpage_left_bigdesc}>{language === "GE" ? "კეთილი იყოს თქვენი მობრძანება ჯიგაროს ბოქსის ვებ - გვერდზე. გირჩევთ არ დახუროთ საიტი ისე, რომ არ სცადოთ ბოქსის გამოწერა, შემდეგ დაგემოვნება და ბოლოს იმ სიამოვნების მიღება, რომელიც გაფიქრებინებთ მომავალში ამ ყველაფრის გამეორებას." : language === "EN" ? "Welcome to the Jigaro`s Box website. I recommend that you don't close the site without trying to buy the box, then taste it and finally get the pleasure that will make you think about doing it all again in the future." : language ==="RU" ? "Добро пожаловать на сайт Jigaro`s Box. Рекомендую не закрывать сайт, не попробовав купить Набор, затем попробовать ее и наконец получить удовольствие, которое заставит вас задуматься о том, чтобы сделать все это еще раз в будущем." : null}</h3>

    {/* <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "წიფლის შეშაზე ცხლად შებოლილი ქათმის ფრთა" : language === "EN" ? "Hot smoked chicken wing over beech wood" : language ==="RU" ? "Куриное крылышко горячего копчения на буковой древесине" : null}</div>
    <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>1.5 {language === "GE" ? "კგ" : language === "EN" ? "Kg." : language ==="RU" ? "Кг." : null}</div>
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "მწყერის დამარინადებული კვერცხი" : language === "EN" ? "Marinated quail eggs" : language ==="RU" ? "Маринованные перепелиные яйца" : null}</div>
    <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>20 {language === "GE" ? "ცალი" : language === "EN" ? "piece" : language ==="RU" ? "Шт." : null}</div>
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ქამა სოკოს მარინადი ბოსტნეულით" : language === "EN" ? "Ham mushroom marinade with vegetables" : language ==="RU" ? "Маринад из грибов и овощей" : null}</div>
    <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>400 {language === "GE" ? "გრ" : language === "EN" ? "Gm." : language ==="RU" ? "Гр." : null}</div>
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გამოცხვარი ნივრიანი პური" : language === "EN" ? "Baked garlic bread" : language ==="RU" ? "Запеченный чесночный хлеб" : null}</div>
    <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>10 {language === "GE" ? "ნაჭერი" : language === "EN" ? "Slice" : language ==="RU" ? "Шт." : null}</div>
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ქართული მჟავის ასორტი" : language === "EN" ? "Assorted Georgian marinaded vegetables" : language ==="RU" ? "Ассорти грузинских маринованных овощей" : null}</div>
    <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>300 {language === "GE" ? "გრ" : language === "EN" ? "Gm." : language ==="RU" ? "Гр." : null}</div>
    </div> */}

    <div className={jigaro.firstpage_left_button_cont}>
    <a className={jigaro.firstpage_left_order_button} onClick={() => boxes.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "შეუკვეთე" : language === "EN" ? "ORDER NOW" : language ==="RU" ? "Заказать" : null}</a>
    <a className={jigaro.firstpage_left_order_video}>
    <div className={jigaro.firstpage_left_video_icon}></div>
    <div className={jigaro.firstpage_left_video_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ვიდეოს ნახვა" : language === "EN" ? "View the video" : language ==="RU" ? "Посмотреть видео" : null}</div>
    </a>
    </div>
    
    </div>
    <div className={`${jigaro.firstpage_right} ${firstpageanim === true ? "animate__animated" : null} ${firstpageanim === true ? "animate__fadeInRight" : null}`}></div>
    </div>

    <div className={jigaro.secondpage} ref={boxes}>
    <div className={`${jigaro.secondpage_dec_text} ${secondpageanim === true ? "animate__animated" : null} ${secondpageanim === true ? "animate__zoomInDown" : null}`} ref={secondpageanimation} style={language === "EN" ? {letterSpacing: "0.1em",fontFamily: "bigtext", fontWeight: "800",  marginLeft: "-3px" ,fontSize: "7.50vw"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200",marginLeft: "-10px", fontSize: "7.99vw"} :  null}>{language === "GE" ? "ჩვენი ბოქსები" : language === "EN" ? "ALL OUR PRODUCTS" : language ==="RU" ? "НАШИ ПРОДУКТЫ" : null}</div>
    <div className={jigaro.secondpage_container}>
    <div className={`${jigaro.secondpage_item_container1} ${order === true ? jigaro.heigfix : null} ${secondpageanim === true ? "animate__animated" : null} ${secondpageanim === true ? "animate__zoomInUp" : null} ${secondpageanim === true ? "animate__delay-1s" : null}`}>
    <div className={`${jigaro.secondpage_container_item1} ${order === true ? jigaro.heigfix : null}`} >
    <div className={jigaro.secondpage_container_item_container}>
    <div className={jigaro.secondpage_container_item_image1}></div>
    <div className={jigaro.secondpage_container_item_title} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ფრთების ბოქსი" : language === "EN" ? "WINGS BOX" : language ==="RU" ? "НАБОР КРЫЛЫШЕК" : null}</div>
    {/* <div className={jigaro.secondpage_container_item_desc} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>წიფლის შეშაზე ცხლად შებოლილი ქათმის ფრთა, მწყერის დამარინადებული კვერცხი, ქამა სოკოს მარინადი ბოსტნეულით, გამომცხვარი ნივრიანი პური, ქართული მჟავის ასორტი</div> */}
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "შეშაზე ცხლად შებოლილი ქათმის ფრთა" : language === "EN" ? "Hot smoked chicken wing over beech wood" : language ==="RU" ? "крылышко горячего копчения на древесине" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>1.5 {language === "GE" ? "კგ" : language === "EN" ? "Kg." : language ==="RU" ? "Кг." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "მწყერის დამარინადებული კვერცხი" : language === "EN" ? "Marinated quail eggs" : language ==="RU" ? "Маринованные перепелиные яйца" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>20 {language === "GE" ? "ცალი" : language === "EN" ? "piece" : language ==="RU" ? "Шт." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ქამა სოკოს მარინადი ბოსტნეულით" : language === "EN" ? "Ham mushroom marinade with vegetables" : language ==="RU" ? "Маринад из грибов и овощей" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>400 {language === "GE" ? "გრ" : language === "EN" ? "Gm." : language ==="RU" ? "Гр." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გამოცხვარი ნივრიანი პური" : language === "EN" ? "Baked garlic bread" : language ==="RU" ? "Запеченный чесночный хлеб" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>10 {language === "GE" ? "ნაჭერი" : language === "EN" ? "Slice" : language ==="RU" ? "Шт." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ქართული მჟავის ასორტი" : language === "EN" ? "Assorted Georgian marinaded vegetables" : language ==="RU" ? "Ассорти грузинских маринованных овощей" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>300 {language === "GE" ? "გრ" : language === "EN" ? "Gm." : language ==="RU" ? "Гр." : null}</div> */}
    </div>
    <a className={`${order === false ? jigaro.secondpage_container_item_button : null} ${order === true ? jigaro.btnact : null}`} onClick={() => setorder(true)} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null} data-hover={language === "GE" ? "99 ლარი" : language === "EN" ? "99 LARI" : language === "RU" ? "99 ЛАРИ" : null}>{order === false ? language === "GE" ? "შეკვეთა" : language === "EN" ? "ORDER NOW" : language ==="RU" ? "Заказать" : null : null}
    {order === true ?
    <div className={jigaro.order_cont}>
    <div className={jigaro.order_active_title} style={language === "EN" ? { fontSize: "18px" ,fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontSize: "18px" ,fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "შესაკვეთად დაგვიკავშირდით" : language === "EN" ? "Contact us to order our box" : language ==="RU" ? "Свяжитесь с нами, чтобы заказать" : null}</div>
    <div className={jigaro.order_active_buttons}>
    <div className={jigaro.order_active_button1}>
      
    </div>   
    <div className={jigaro.order_active_button2}>
      
      </div> 
      <div className={jigaro.order_active_button3}>
      
      </div> 
    </div>
    <div className={jigaro.order_active_warning} style={language === "EN" ? { fontSize: "14px" ,fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontSize: "14px" ,fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გაითვალისწინეთ, რომ შეკვეთის დამზადებას სჭირდება დრო, 3 საათამდე." : language === "EN" ? "Please note that it takes up to 3 hours for the order to be made." : language ==="RU" ? "Обратите внимание, что выполнение заказа занимает до 3 часов." : null}</div>
    </div>
    : null}
    </a>
    </div>
    </div>
    <div className={jigaro.secondpage_item_warning} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გაითვალისწინეთ, რომ შეკვეთის დამზადებას სჭირდება დრო, 3 საათამდე." : language === "EN" ? "Please note that it takes up to 3 hours for the order to be made." : language ==="RU" ? "Обратите внимание, что выполнение заказа занимает до 3 часов." : null}</div>
    </div>
    <div className={`${jigaro.secondpage_item_container2} ${order === true ? jigaro.heigfix : null} ${secondpageanim === true ? "animate__animated" : null} ${secondpageanim === true ? "animate__zoomInUp" : null} ${secondpageanim === true ? "animate__delay-2s" : null}`}>
    <div className={jigaro.secondpage_container_item2}>
    <div className={jigaro.secondpage_container_item_container}>
    <div className={jigaro.secondpage_container_item_image1}></div>
    <div className={jigaro.secondpage_container_item_title} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ნეკნების ბოქსი" : language === "EN" ? "PORK RIBS BOX" : language ==="RU" ? "НАБОР РЕБРЫШЕК" : null}</div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გრილზე შებოლილი ღორის ნეკნები ლუდში" : language === "EN" ? "Grilled smoked pork ribs in beer" : language ==="RU" ? "Свиные ребрышки копченые на гриле в пиве" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>1.5 {language === "GE" ? "კგ" : language === "EN" ? "Kg." : language ==="RU" ? "Кг." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "მწყერის დამარინადებული კვერცხი" : language === "EN" ? "Marinated quail eggs" : language ==="RU" ? "Маринованные перепелиные яйца" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>20 {language === "GE" ? "ცალი" : language === "EN" ? "piece" : language ==="RU" ? "Шт." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ქამა სოკოს მარინადი ბოსტნეულით" : language === "EN" ? "Ham mushroom marinade with vegetables" : language ==="RU" ? "Маринад из грибов и овощей" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>400 {language === "GE" ? "გრ" : language === "EN" ? "Gm." : language ==="RU" ? "Гр." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გამოცხვარი ნივრიანი პური" : language === "EN" ? "Baked garlic bread" : language ==="RU" ? "Запеченный чесночный хлеб" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>10 {language === "GE" ? "ნაჭერი" : language === "EN" ? "Slice" : language ==="RU" ? "Шт." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ქართული მჟავის ასორტი" : language === "EN" ? "Assorted Georgian marinaded vegetables" : language ==="RU" ? "Ассорти грузинских маринованных овощей" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>300 {language === "GE" ? "გრ" : language === "EN" ? "Gm." : language ==="RU" ? "Гр." : null}</div> */}
    </div>
    <a className={jigaro.secondpage_container_item_button} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null} data-hover={language === "GE" ? "99 ლარი" : language === "EN" ? "99 LARI" : language === "RU" ? "99 ЛАРИ" : null}>{language === "GE" ? "შეკვეთა" : language === "EN" ? "ORDER NOW" : language ==="RU" ? "Заказать" : null}</a>
    </div>
    </div>
    <div className={jigaro.secondpage_item_warning} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გაითვალისწინეთ, რომ შეკვეთის დამზადებას სჭირდება დრო, 3 საათამდე." : language === "EN" ? "Please note that it takes up to 3 hours for the order to be made." : language ==="RU" ? "Обратите внимание, что выполнение заказа занимает до 3 часов." : null}</div>
    </div>
    <div className={`${jigaro.secondpage_item_container3} ${order === true ? jigaro.heigfix : null} ${secondpageanim === true ? "animate__animated" : null} ${secondpageanim === true ? "animate__zoomInUp" : null} ${secondpageanim === true ? "animate__delay-3s" : null}`}>
    <div className={jigaro.secondpage_container_item3}>
    <div className={jigaro.secondpage_container_item_container}>
    <div className={jigaro.secondpage_container_item_image1}></div>
    <div className={jigaro.secondpage_container_item_title} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "შერეული ბოქსი" : language === "EN" ? "MIXED BOX" : language ==="RU" ? "СМЕШАННЫЙ НАБОР" : null}</div>
    {/* <div className={jigaro.secondpage_container_item_desc} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>დროებით ხელმიუწვდომელია</div> */}
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "შებოლილი ღორის ნეკნები და ქათმის ფრთები" : language === "EN" ? "Smoked pork ribs and chicken wings" : language ==="RU" ? "Копченые ребрышки и куриные крылышки" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>20 {language === "GE" ? "ცალი" : language === "EN" ? "piece" : language ==="RU" ? "Шт." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "მწყერის დამარინადებული კვერცხი" : language === "EN" ? "Marinated quail eggs" : language ==="RU" ? "Маринованные перепелиные яйца" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>20 {language === "GE" ? "ცალი" : language === "EN" ? "piece" : language ==="RU" ? "Шт." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ქამა სოკოს მარინადი ბოსტნეულით" : language === "EN" ? "Ham mushroom marinade with vegetables" : language ==="RU" ? "Маринад из грибов и овощей" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>400 {language === "GE" ? "გრ" : language === "EN" ? "Gm." : language ==="RU" ? "Гр." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გამოცხვარი ნივრიანი პური" : language === "EN" ? "Baked garlic bread" : language ==="RU" ? "Запеченный чесночный хлеб" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>10 {language === "GE" ? "ნაჭერი" : language === "EN" ? "Slice" : language ==="RU" ? "Шт." : null}</div> */}
    </div>
    <div className={jigaro.firstpage_left_option_cont}>
    <div className={jigaro.firstpage_left_option_icon}></div>
    <div className={jigaro.firstpage_left_option_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ქართული მჟავის ასორტი" : language === "EN" ? "Assorted Georgian marinaded vegetables" : language ==="RU" ? "Ассорти грузинских маринованных овощей" : null}</div>
    {/* <div className={jigaro.firstpage_left_option_quantity} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>300 {language === "GE" ? "გრ" : language === "EN" ? "Gm." : language ==="RU" ? "Гр." : null}</div> */}
    </div>
    <a className={jigaro.secondpage_container_item_button} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null} data-hover={language === "GE" ? "99 ლარი" : language === "EN" ? "99 LARI" : language === "RU" ? "99 ЛАРИ" : null}>{ language === "GE" ? "შეკვეთა" : language === "EN" ? "ORDER NOW" : language ==="RU" ? "Заказать" : null}</a>
    </div>
    </div>
    <div className={jigaro.secondpage_item_warning} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გაითვალისწინეთ, რომ შეკვეთის დამზადებას სჭირდება დრო, 3 საათამდე." : language === "EN" ? "Please note that it takes up to 3 hours for the order to be made." : language ==="RU" ? "Обратите внимание, что выполнение заказа занимает до 3 часов." : null}</div>
    </div>
    </div>
    </div>

    <div className={jigaro.thirdpage} ref={whyus}>
    <div className={jigaro.thirdpage_container} ref={thirdpageanimation}>
    <div className={`${jigaro.thirdpage_dec_text} ${thirdpageanim === true ? "animate__animated" : null} ${thirdpageanim === true ? "animate__zoomInUp" : null}`} style={language === "EN" ? {letterSpacing: "0.1em",fontFamily: "bigtext", fontWeight: "800", fontSize: "14.5vw", lineHeight: "16.84vw"} : language === "RU" ? {fontFamily: "segoe", fontSize: "16vw", fontWeight: "200"} :  null}>{language === "GE" ? "რატომ ჩვენ" : language === "EN" ? "WHY OUR BOX?" : language ==="RU" ? "ПОЧЕМУ МЫ?" : null}</div>
    <div className={`${jigaro.thirdpage_left} ${thirdpageanim === true ? "animate__animated" : null} ${thirdpageanim === true ? "animate__fadeInLeft" : null}`}>
    <div className={jigaro.thirdpage_left_title} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "რატომ ჩვენ?" : language === "EN" ? "WHY OUR BOX?" : language ==="RU" ? "ПОЧЕМУ МЫ?" : null}</div>  
    <div className={jigaro.thirdpage_left_desc} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "პასუხი მარტივია: განსაკუთრებული ტექნიკა და საკმაზები, ცხელი ბოლი გრილში შეშაზე, არანაირი ცხიმი და წვის მავნე პროდუქტები." : language === "EN" ? "The answer is simple: special technique and sufficient, hot smoked in the grill on wood, no fat and harmful combustion products." : language ==="RU" ? "Ответ прост: специальная техника и горячее копчение в мангале на дровах, без жира и вредных продуктов горения." : null}</div>  
    <div className={jigaro.thirdpage_left_warning} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "გაითვალისწინეთ, რომ შეკვეთის დამზადებას სჭირდება დრო, 3 საათამდე." : language === "EN" ? "Please note that it takes up to 3 hours for the order to be made." : language ==="RU" ? "Обратите внимание, что выполнение заказа занимает до 3 часов." : null}</div>  
    </div> 
    <div className={`${jigaro.thirdpage_right} ${thirdpageanim === true ? "animate__animated" : null} ${thirdpageanim === true ? "animate__fadeInRight" : null}`}></div> 
    </div>

    </div>
    

    </div>
    
    <div className={jigaro.footer} ref={contact}>
    <div className={jigaro.footer_container}>
    <div className={jigaro.footer_left_cont}>
    <div className={jigaro.footer_title_cont}>
    <div className={jigaro.footer_title_1}>© COPYRIGHT 2024 JIGAROS BOX</div>
    <div className={jigaro.footer_title_2}>POWERED BY <a target="_blank" href='https://codium.ge/' style={{color: "#9369fd86"}}>CODIUM.GE</a></div>
    </div>
    <div className={`${jigaro.footer_pages} ${jigaro.pgs}`}>
    <a className={jigaro.footer_page} onClick={() => main.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>
    <div className={jigaro.footer_page_icon}></div>
    <div className={jigaro.footer_page_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "მთავარი გვერდი" : language === "EN" ? "Main Page" : language ==="RU" ? "Главная Страница" : null}</div>
    </a>
    <a className={jigaro.footer_page} onClick={() => boxes.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>
    <div className={jigaro.footer_page_icon}></div>
    <div className={jigaro.footer_page_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "ბოქსები" : language === "EN" ? "Our Boxes" : language ==="RU" ? "Наборы" : null}</div>
    </a>
    <a className={jigaro.footer_page} onClick={() => whyus.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}>
    <div className={jigaro.footer_page_icon}></div>
    <div className={jigaro.footer_page_text} style={language === "EN" ? {fontFamily: "Nunito", fontWeight: "800"} : language === "RU" ? {fontFamily: "segoe", fontWeight: "200"} :  null}>{language === "GE" ? "რატომ ჩვენ" : language === "EN" ? "Why Us" : language ==="RU" ? "Почему Мы" : null}</div>
    </a>
    </div>

    <div className={jigaro.footer_pages}>
    <a className={jigaro.footer_page}>
    <div className={jigaro.footer_page_icon1}></div>
    <div className={jigaro.footer_page_text}>jigaro.ge</div>
    </a>
    <a className={jigaro.footer_page}>
    <div className={jigaro.footer_page_icon2}></div>
    <div className={jigaro.footer_page_text}>jigaro.ge</div>
    </a>
    <a className={jigaro.footer_page}>
    <div className={jigaro.footer_page_icon3}></div>
    <div className={jigaro.footer_page_text}>+995 577 00 00 00</div>
    </a>
    </div>
    </div>
    <a className={jigaro.footer_back} onClick={() => main.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}></a>
    </div>
    </div>

    </main>
    </div>
  

  );
  
}

export default App;
